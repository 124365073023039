import React from 'react';
import { isMobileScreen } from '../../../utils/helpers';
import paypal from '../../../assets/badges/secured-payment-paypal.png';
import stripe from '../../../assets/badges/secured-payment-stripe.png';
import stripeAndPaypal from '../../../assets/badges/paypal_stripe.png';
const TrustBadges = () => {
	return (
		<React.Fragment>
			<div
				className='hide-on-small-and-down d-flex mb-4'
				style={{ justifyContent: 'end', alignItems: 'center' }}>
				{/* <!-- Stripe Logo --> */}
				{/* <div>
					<img src={stripe} width='320' alt='Stripe Secure' />
				</div> */}
				{/* <!-- PayPal Logo --> */}
				<div>
					<img src={paypal} border='0' alt='PayPal Acceptance Mark' />
				</div>
			</div>
			<div className='hide-on-med-and-up center mb-4'>
				<img src={stripeAndPaypal} border='0' alt='PayPal Acceptance Mark' />
			</div>
		</React.Fragment>
	);
};

export default TrustBadges;
