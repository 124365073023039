import React, { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { scrollToTop } from '../../../utils/helpers';
import { AuthContext } from '../../../utils/store/context/authContext';
import { AdminContext } from '../../../utils/store/context/adminContext';

// COMPONENTS
import NavLinksAdmin from './NavLinksAdmin';
import NavLinksStaff from './NavLinksStaff';
import M from 'materialize-css';
import NavLinksUser from './NavLinksUser';

const SignedInLinks = (props) => {
	const { user, signOut } = useContext(AuthContext);
	const { enurmData, nextEnurm } = useContext(AdminContext);
	// const [showLinks, setShowLinks] = React.useState(true);

	React.useEffect(() => {
		scrollToTop();
		M.AutoInit();
	}, []);

	return (
		<Fragment>
			<a
				href='#!'
				data-target='mobile-menu-signed-in'
				className='sidenav-trigger'>
				<i className='material-icons'>menu</i>
			</a>
			<ul className='right hide-on-med-and-down'>
				{/* //? Should we show logout on checkout screens?*/}

				{/* //! Admin only pages  */}
				{user.isAdmin && (
					<React.Fragment>
						<li>
							<a className='dropdown-trigger' href='#!' data-target='dropdown1'>
								Admin Mgmt Links
								<i className='material-icons right'>arrow_drop_down</i>
							</a>
						</li>
					</React.Fragment>
				)}

				{/* //! Staff only pages  */}
				{user.isStaff && (
					<React.Fragment>
						<li>
							<a className='dropdown-trigger' href='#!' data-target='dropdown1'>
								Team Member Links
								<i className='material-icons right'>arrow_drop_down</i>
							</a>
						</li>
					</React.Fragment>
				)}

				{/* /// USER NO MEMBERSHIP PAID */}
				{!user[`membership_${nextEnurm}`] && (
					<li>
						<NavLink to='#' id='log-off' onClick={signOut}>
							Cerrar Sesión
						</NavLink>
					</li>
				)}

				{/* /// USER WITH MEMBERSHIP PAID OR FREE TRIAL*/}
				{(user[`membership_${nextEnurm}`]?.isPaid || user.freeTrialActive) && (
					<React.Fragment>
						<li>
							<a className='dropdown-trigger' href='#!' data-target='dropdown1'>
								Tus Opciones
								<i className='material-icons right'>arrow_drop_down</i>
							</a>
						</li>
						<li>
							<NavLink to='#' id='log-off' onClick={signOut}>
								Cerrar Sesión
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/tu-perfil'
								className='btn btn-floating bg-brand white-text'>
								{user.initials}
							</NavLink>
						</li>
					</React.Fragment>
				)}
			</ul>

			<ul className='right hide-on-large-only'>
				<li>
					<NavLink
						to='/tu-perfil'
						className='btn btn-floating bg-brand white-text'>
						{user.initials}
					</NavLink>
				</li>
			</ul>

			{(user[`membership_${nextEnurm}`]?.isPaid || user.freeTrialActive) && (
				<NavLinksUser />
			)}
			{user.isAdmin && <NavLinksAdmin />}
			{user.isStaff && <NavLinksStaff />}
		</Fragment>
	);
};

export default SignedInLinks;
