import React from 'react';

const InputNota = ({ handleChange }) => {
	const ref = React.useRef('');
	const [showFormatoValido, setShowFormatoValido] = React.useState(false);

	return (
		<>
			<div className='input-field col s12 m6'>
				<i className='material-icons prefix txt-brand2'>grade</i>
				<label htmlFor='nota'>Nota en el ENURM</label>
				<input
					type='text'
					id='nota'
					ref={ref}
					onFocus={(e) => setShowFormatoValido(true)}
					onBlur={(e) => setShowFormatoValido(false)}
					onChange={(e) => handleChange(e.target.value)}
					minLength={2}
					maxLength={3}
					pattern='[0-9]{{2,3}}'
					autoComplete='off'
					required
				/>
				{ref.current.value !== '' && (
					<span className={ref.current?.validity && 'invalid'}></span>
				)}
				<sup className='ml-4'>(requerido)</sup>
			</div>
			{showFormatoValido && (
				<div className='col s12'>
					<div className='col offset-m6 m6 s12'>
						<p className='center fsize-1 txt-danger m-0'>
							Formatos Válidos: ##
						</p>
					</div>
				</div>
			)}
		</>
	);
};

export default InputNota;
