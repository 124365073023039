import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

const SignedOutLinks = () => {
	return (
		<Fragment>
			<a
				href='#!'
				data-target='mobile-nav-menu-signed-out'
				className='sidenav-trigger'>
				<i className='material-icons'>menu</i>
			</a>

			<ul className='right hide-on-med-and-down'>
				<li>
					<NavLink to='/sobre-nosotros'>Sobre Nosotros</NavLink>
				</li>
				<li>
					<NavLink to='/acceder'>Iniciar Sesión</NavLink>
				</li>
				<li className='bg-brand1'>
					<NavLink to='/registrate'>Crea tu Cuenta</NavLink>
				</li>
			</ul>

			{/*ul sidenav goes here*/}
		</Fragment>
	);
};

export default SignedOutLinks;
