import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { isMobileScreen, scrollToTop } from '../../../../utils/helpers';
// import M from 'materialize-css';
import SelectFromEspecialidades from '../../../Helpers/SelectFromEspecialidades';

const Step3 = ({
	nextStep,
	thisUser,
	updateThisUser,
	updateNewUserInformationInFirestore,
}) => {
	//~ REFS
	const uniRef = useRef();
	const gradYearRef = useRef();

	//~ INPUT FIELDS
	const [uni, setUni] = useState('N/A');
	const [gradYear, setGradYear] = useState('N/A');

	//~ VALIDATION STATES
	const [allFieldsValid, setAllFieldsValid] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState('');

	const checkIfAllFieldsValid = () => {
		setShowErrorMessage('');

		if (uni !== 'N/A' && gradYear !== 'N/A') {
			setAllFieldsValid(true);
			setShowErrorMessage('');
			// console.info('All fields valid.');
			return;
		} else {
			setAllFieldsValid(false);

			if (uni === 'N/A' || uni === '') {
				setShowErrorMessage('Por favor verifica el campo "Universidad".');
				return;
			}
			if (gradYear === 'N/A' || gradYear === '') {
				setShowErrorMessage(
					'Por favor verifica el campo "Año de Graduacción".'
				);
				return;
			}
		}
	};

	const handleChange = (e) => {
		// console.log('HANDLE CHANGE', e.target.value);
		e.target.setCustomValidity('');
		switch (e.target.id) {
			case 'universidad':
				setUni(e.target.value);
				break;
			case 'graduando':
				setGradYear(e.target.value);
				break;
			default:
				break;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Próximo Paso
		updateThisUser({
			universidad: uni,
			gradYear,
			...thisUser,
		});
		updateNewUserInformationInFirestore(thisUser.uid, {
			universidad: uni,
			gradYear,
			...thisUser,
		});
		nextStep();
		scrollToTop();
	};

	// useEffect(() => {
	// 	M.AutoInit();
	// }, []);

	useEffect(() => {
		checkIfAllFieldsValid();
		// eslint-disable-next-line
	}, [uni, gradYear]);

	return (
		<form onSubmit={handleSubmit} className='white row' autoComplete='off'>
			{/* //~ UNIVERSIDADES */}
			<div className='input-field col s12'>
				<div>
					<i className='material-icons txt-brand'>apartment</i>
					<span style={{ marginLeft: '2rem', color: '#9e9e9e' }}>
						Universidad
					</span>
				</div>
				<select
					name='universidad'
					id='universidad'
					className='browser-default'
					style={{ marginLeft: '3rem', width: '90%' }}
					ref={uniRef}
					onChange={handleChange}
					defaultValue={'N/A'}
					required>
					<option value='N/A' disabled>
						-Selecciona Una-
					</option>
					<option value='INTEC'>INTEC</option>
					<option value={`O&M`}>{`O&M`}</option>
					<option value='PUCMM'>PUCMM</option>
					<option value='UASD'>UASD</option>
					<option value='UCATECI'>UCATECI</option>
					<option value='UCE'>UCE</option>
					<option value='UCNE'>UCNE</option>
					<option value='UNIBE'>UNIBE</option>
					<option value='UNPHU'>UNPHU</option>
					<option value='UTESA'>UTESA</option>
					<option value='otra'>Otra universidad</option>
				</select>

				<sup className='ml-4'>(requerido)</sup>
			</div>
			{/* //~ GRADUACION | GRADYEAR */}
			<div className='input-field col s12'>
				<div>
					<i className='material-icons txt-brand'>school</i>
					<span style={{ marginLeft: '2rem', color: '#9e9e9e' }}>
						Año de Graduación
					</span>
				</div>
				<select
					name='graduando'
					id='graduando'
					className='browser-default'
					style={{ marginLeft: '3rem', width: '90%' }}
					ref={gradYearRef}
					onChange={handleChange}
					defaultValue={'N/A'}
					required>
					<option value='N/A' disabled>
						-Selecciona Uno-
					</option>
					<option value='2003'>2003</option>
					<option value='2004'>2004</option>
					<option value='2005'>2005</option>
					<option value='2006'>2006</option>
					<option value='2007'>2007</option>
					<option value='2008'>2008</option>
					<option value='2009'>2009</option>
					<option value='2010'>2010</option>
					<option value='2011'>2011</option>
					<option value='2012'>2012</option>
					<option value='2013'>2013</option>
					<option value='2014'>2014</option>
					<option value='2015'>2015</option>
					<option value='2016'>2016</option>
					<option value='2017'>2017</option>
					<option value='2018'>2018</option>
					<option value='2019'>2019</option>
					<option value='2020'>2020</option>
					<option value='2021'>2021</option>
					<option value='2022'>2022</option>
					<option value='2023'>2023</option>
					<option value='2024'>2024</option>
					<option value='2025'>2025</option>
					<option value='2026'>2026</option>
					<option value='2027'>2027</option>
					<option value='2028'>2028</option>
					<option value='2029'>2029</option>
					<option value='2030'>2030</option>
					<option value='otro'>Otro año</option>
				</select>
				<sup className='ml-4'>(requerido)</sup>
			</div>

			{/*//~ VALIDATION ERRORS */}
			{!allFieldsValid && showErrorMessage && (
				<div
					className='txt-danger center-align col s12'
					style={{ display: 'block' }}>
					<p className='bg-danger p-1 white-text center'>{showErrorMessage}</p>
				</div>
			)}

			{/*//~ SIGNUP BUTTON */}
			{allFieldsValid && (
				<div className='center'>
					<p className='txt-brand2'>Presiona el botón para continuar.</p>
				</div>
			)}

			{/* //~NEXT STEP MESSAGE */}
			<div className='right-align mb-4'>
				<button
					disabled={!allFieldsValid}
					className='btn btn-large bg-brand'
					// onClick={(e) => setDoB(dobRef.current.value)}
					type='submit'>
					Continuar
					<i className='material-icons right'>send</i>
				</button>
			</div>
		</form>
	);
};

export default Step3;
