import React from 'react';
import logo from '../assets/logo/maxilofacial.png';
import { deleteAllCookies, isMobileScreen } from '../utils/helpers';
import { logEvent, logError } from '../utils/store/config/fbConfig';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
		logEvent('Reached Error Boundary');
		logEvent(`ErrBoundary: ${window.location.pathname}`);
		logError(error, errorInfo, window.location.pathname, true);
	}

	clearIssues = (params) => {
		deleteAllCookies();
	};

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<React.Fragment>
					<div style={{ height: '100vh' }} className='bg-dark'>
						<div className='container'>
							<div className='col s12 center'>
								<img
									src={logo}
									alt='prepmed-logo'
									className='img-reponsive w-65 pt-5'
									style={{ maxWidth: '600px' }}
								/>
							</div>
							<div className='center mt-4'>
								<h2 className='txt-danger '>
									<i className='material-icons fsize-larger mr-2'>cancel</i>
									¡Importante!
								</h2>
								<div
									className=' white-text mt-3 text-left'
									style={{
										maxWidth: isMobileScreen ? '90%' : '70%',
										margin: '0 auto',
									}}>
									<p>
										Por favor intenta denuevo, hubo un error y es necesario
										recargar la aplicación.
									</p>
									<p>
										<strong>Importante:</strong> Antes de recargar, si tienes un
										momento, por favor dejanos saber que pasos tomaste antes de
										encontrar este error. Esta información es vital para mejorar
										la plataforma y solo toma un segundo contactarnos usando
										este enlace:
									</p>

									<a href='./contactanos' className='btn btn-large bg-brand'>
										Contactanos
									</a>
									<div className='pt-4'>
										<button
											className='btn btn-large bg-brand2'
											onClick={this.clearIssues}>
											<i className='material-icons left'>cached</i>
											Recargar App
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
