import React, { useContext } from 'react';
import { AuthContext } from '../../../utils/store/context/authContext';
import LoadingSection from '../../Helpers/LoadingSection';
import QuestionsProgressBar from './QuestionsProgressBar';
import Stats from './Stats';

import TestRecordDetails from './TestRecordDetails';
import TestRecordTable from './TestRecordTable';

const Historial = (props) => {
	const { user, userTestRecords } = useContext(AuthContext);

	// console.info(userTestRecords);

	//~  USER HAS NO TEST RECORDS - ALERT
	if (!user.hasOwnProperty('totalTestRecords')) {
		return (
			<section className='section-2 col s12'>
				<div className='container'>
					<h3>Historial de Exámenes</h3>
					<div>
						<p className='card-panel bg-alert'>
							Cuando completes al menos un examen aquí verás tus resultados...
						</p>
					</div>
				</div>
			</section>
		);
	}
	if (!userTestRecords) {
		return (
			<LoadingSection message='Analizando tu historial. Puede tomarse hasta 1 minuto en cargar...' />
		);
	}

	return (
		<React.Fragment>
			<section className='section-2 col s12'>
				<div className='container'>
					<h3>Historial de Exámenes</h3>
					{/* USER HAS TEST RECORDS */}
					<div className={userTestRecords.length > 0 ? '' : 'd-none'}>
						{/* TEST RECORD DETAILS */}
						{userTestRecords && <TestRecordDetails records={userTestRecords} />}
					</div>
				</div>
			</section>

			{userTestRecords.length > 0 && (
				<>
					<QuestionsProgressBar records={userTestRecords} />
					<TestRecordTable records={userTestRecords} />
					<Stats />
				</>
			)}
		</React.Fragment>
	);
};

export default Historial;
