import React from 'react';
import { AdminContext } from '../../../../utils/store/context/adminContext';
import { useSortableData } from '../../../Helpers/useSortableData';
import SelectENURMYear from '../../../Helpers/SelectENURMYear';
import SalesSummaryReport from './SalesSummaryReport';

const ListaDeUsuariosNoPagos = (props) => {
	// TODO: add checkboxes to include / remove columns with Object.entries().map(x=>{}) on the document I'm calling
	const { getUsersWhere } = React.useContext(AdminContext);

	const [searchSubmitted, setSearchSubmitted] = React.useState(false);
	const [reportData, setReportData] = React.useState([]);
	const [reportYear, setReportYear] = React.useState([]);
	const [year, setYear] = React.useState();

	let totalCorrectas, totalPreguntas;

	const { items, requestSort } = useSortableData(reportData, {
		key: 'signUpDate',
		direction: 'descending',
	});

	React.useEffect(() => {
		if (reportYear.length > 0) {
			let date = new Date(reportYear);
			let yr = date.getFullYear() + 1;
			setYear(yr);
		}

		return () => {
			setSearchSubmitted(false);
		};
		// eslint-disable-next-line
	}, [reportYear]);

	const handleSubmit = async () => {
		let data = await getUsersWhere(`isClient_${year}`, '==', false);
		console.info('Report Data:', data);

		// getActiveUsers(data);
		setReportData(data);
		setSearchSubmitted(true);
	};

	const getAverageGrade = (user) => {
		if (user.testRecords) {
			totalCorrectas = user[`testRecords_${year}`].reduce(
				(sum, currentValue) => {
					return sum + currentValue.score;
				},
				0
			);
			totalPreguntas = user[`testRecords_${year}`].reduce(
				(sum, currentValue) => {
					return sum + currentValue.testLength;
				},
				0
			);
			return Math.round(
				((totalCorrectas + Number.EPSILON) / totalPreguntas) * 100
			);
		}
		return 'n/a';
	};
	return (
		<div>
			<h4>Lista de Clientes/Usuarios No Pagos</h4>
			<SelectENURMYear onChange={(yr) => setReportYear(yr)} />
			{!searchSubmitted && (
				<div className='input-field col s12'>
					<button
						type='submit'
						className='btn bg-brand'
						disabled={year === undefined}
						onClick={handleSubmit}>
						Submit
					</button>
				</div>
			)}

			{searchSubmitted && (
				<div>
					{reportData.length > 0 ? (
						<>
							<SalesSummaryReport reportData={reportData} year={year} />
							<div style={{ overflowX: 'auto' }}>
								<table id='user-records'>
									<thead>
										<tr>
											<td>#</td>
											<td>
												<span
													className='c-pointer'
													onClick={() => requestSort('firstName')}>
													Nombre
												</span>
											</td>
											<td>
												<span
													className='c-pointer'
													onClick={() => requestSort('lastName')}>
													Apellido
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('email')}>
													Email
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('signUpDate')}>
													Signup Date
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('isClient_2022')}>
													Billing Type
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('paymentCompleted')}>
													Paid
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('totalTestRecords')}>
													Total Tests
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('lastTestDate')}>
													Last Test
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('firstTestScore')}>
													First Score
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('lastTestScore')}>
													Last Score
												</span>
											</td>
											<td className='center'>
												<span
													className='c-pointer'
													onClick={() => requestSort('avgGrade')}>
													AVG Score
												</span>
											</td>
										</tr>
									</thead>
									<tbody>
										{reportData &&
											items.map((u) => {
												return (
													<tr key={u.id}>
														<td>{items.indexOf(u) + 1}</td>
														<td>{u.firstName}</td>
														<td>{u.lastName}</td>
														<td className='center'>{u.email}</td>
														<td className='center'>
															{u.signUpDate.toDate().toString().substr(0, 16)}
														</td>
														<td className='center'>
															{u.isAdmin
																? 'ADMIN'
																: u[`isClient_${year}`]
																? 'Cliente'
																: u[`membership_${year}`]?.type}
														</td>
														<td className='center'>
															{u.isAdmin ? (
																'-'
															) : u[`membership_${year}`]?.paymentSource ? (
																<i className='material-icons'>done</i>
															) : (
																<i className='material-icons txt-danger'>
																	error
																</i>
															)}
														</td>
														<td className='center'>
															{u.totalTestRecords
																? u.totalTestRecords
																: 'No test'}
														</td>
														<td className='center'>
															{u.lastTestDate
																? u.lastTestDate
																		.toDate()
																		.toDateString()
																		.substr(0, 16)
																: 'No test'}
														</td>
														{/*  */}
														<td className='center'>
															{u.firstTestScore ? u.firstTestScore : 'No test'}
														</td>
														{/* last score */}
														<td className='center'>
															{u.lastTestScore
																? `${u.lastTestScore}%`
																: 'No test'}
														</td>
														{/* avg score */}
														<td className='center'>
															{/* {u.testRecords ? `${getAverageGrade(u)}%` : null} */}
															{u.avgGrade
																? `${u.avgGrade}%`
																: u[`testRecords_${year}`]
																? `${getAverageGrade(u)}%`
																: 'No test'}
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						</>
					) : (
						<div>
							<p>No users found...</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ListaDeUsuariosNoPagos;
