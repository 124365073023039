import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo/maxilofacial.png';

import {
	ABOUT_US,
	CONTACT_US,
	PRIVACY_POLICY,
	TOS_PAGE,
	POLICY_COOKIES,
	SIGNUP_PAGE,
} from '../../utils/ROUTES';
import { AuthContext } from '../../utils/store/context/authContext';
function Footer() {
	// ! Footer ideas: https://www.mockplus.com/blog/post/website-footer-design#:~:text=A%20website%20footer%20is%20a,more%20important%20than%20you%20think.

	const { user } = useContext(AuthContext);

	const scrollToTop = () => {
		const top = document.querySelector('div.prepmed');
		top.scrollIntoView({
			alignToTop: true,
			behavior: 'smooth',
		});
	};

	if (user.uid) {
		return (
			<React.Fragment>
				<footer className='py-1'>
					<div className='container'>
						<ul style={{ display: 'flex', justifyContent: 'space-evenly' }}>
							<li>
								<Link to={CONTACT_US} onClick={scrollToTop}>
									Contáctanos
								</Link>
							</li>

							<li>
								<Link to={TOS_PAGE} onClick={scrollToTop}>
									Términos de Servicio
								</Link>
							</li>
						</ul>
					</div>
				</footer>
				{/* //! COPYRIGHT */}
				<div className='copyright'>
					<p className='center'>
						PrepMedRD &#169; Copyright {new Date().getFullYear()} | Todos los
						derechos reservados. PrepMed LLC
					</p>
				</div>
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			<footer>
				<div className='container'>
					{/* //! TRUST BADGES */}
					<div className='trust-badges d-none'>
						<div className='row'>
							<div className='col s2'></div>
							<div className='col s2'></div>
							<div className='col s2'></div>
							<div className='col s2'></div>
							<div className='col s2'></div>
							<div className='col s2'></div>
						</div>
					</div>
					{/* //! MAIN */}
					<div className='row'>
						<div className='col m5 s12'>
							<img
								src={logo}
								alt='prepmed-logo'
								className='img-reponsive w-50'
							/>
							<p>
								PrepDerm es la primera y única herramienta de estudio en línea
								de la República Dominicana completamente dedicada a los médicos
								preparándose para dermatología - Creada por PrepMedRD.
							</p>
						</div>
						<div className='col offset-m1 m3 s6'>
							<h6>Otros Recursos</h6>
							<ul>
								<li>
									<Link to={SIGNUP_PAGE} onClick={scrollToTop}>
										Regístrate
									</Link>
								</li>
								<li>
									<Link to={ABOUT_US} onClick={scrollToTop}>
										Sobre Nosotros
									</Link>
								</li>
								<li>
									<Link to={CONTACT_US} onClick={scrollToTop}>
										Contáctanos
									</Link>
								</li>

								<li>
									<Link to={TOS_PAGE} onClick={scrollToTop}>
										Términos de Servicio
									</Link>
								</li>
							</ul>
						</div>
						<div className='col m3 s6'>
							<h6>Síguenos</h6>
							<ul>
								<li>
									<a href='https://www.instagram.com/prepmedrd/'>Instagram</a>
								</li>
								<li>
									<a href='https://twitter.com/prepmedrd'>Twitter</a>
								</li>
								<li>
									<a href='https://www.facebook.com/prepmedrd'>Facebook</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
			{/* //! COPYRIGHT */}
			<div className='copyright'>
				<p className='center'>
					PrepMedRD &#169; Copyright {new Date().getFullYear()} | Todos los
					derechos reservados. PrepMed LLC
				</p>
			</div>
		</React.Fragment>
	);
}

export default Footer;
