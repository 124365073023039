import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import parseHTML from 'html-react-parser';
import { scrollToTop, shuffle } from '../../../utils/helpers';
import LoadingFull from '../../Helpers/LoadingFull';
import logo from '../../../assets/logo/maxilofacial.png';
import Progress from '../quiz/questionaire/Progress';
import { AuthContext } from '../../../utils/store/context/authContext';

const FlashcardsMode = (props) => {
	const { state } = useLocation();
	const { storeFlashcardsResults } = React.useContext(AuthContext);
	const [theseFlashcards, setTheseFlashcards] = React.useState();
	const [currentCard, setCurrentCard] = React.useState(0);
	const [showProgress, setShowProgress] = React.useState(false);
	const [correctas, setCorrectas] = React.useState([]);

	const [animation, setAnimation] = React.useState('none');
	const [showCard, setShowCard] = React.useState(false);
	const [showBack, setShowBack] = React.useState(false);

	const [loadingFinalResults, setLoadingFinalResults] = React.useState(false);
	const [showFinalResults, setShowFinalResults] = React.useState(false);

	React.useEffect(() => {
		setTheseFlashcards(() =>
			shuffle([...state.availableFlashcards]).slice(0, state.length)
		);
		const nav = document.querySelector('nav');
		nav.classList.add('hide');

		const footer = document.querySelector('footer');
		footer.classList.add('hide');

		const copyright = document.querySelector('.copyright');
		copyright.classList.add('hide');

		const pageHeight = document.querySelector('#page-height');
		pageHeight.style.height = 'auto';
		pageHeight.style.minHeight = '0vh';

		const bgHeight = document.querySelector('#full-bg');
		bgHeight.style.height = '100vh';

		scrollToTop();
		setShowCard(true);
		return () => {
			nav.classList.remove('hide');
			footer.classList.remove('hide');
			copyright.classList.remove('hide');
			// pageHeight.style.height = '100%';
			pageHeight.style.minHeight = '90vh';
			bgHeight.style.height = 'auto';
		};
		// eslint-disable-next-line
	}, []);

	// React.useEffect(() => {
	// 	const bgHeight = document.querySelector('#full-bg');
	// 	bgHeight.style.height = '100vh';

	// 	return () => {
	// 		bgHeight.style.height = 'auto';
	// 	};
	// }, [showFinalResults]);

	const variants = {
		cardBack: {},
		rotate: {
			rotateY: 90,
			transition: { duration: 0.25 },
			// transitionEnd: {
			// 	rotateY: 0,
			// },
		},
		rotateBack: { rotateY: 0, transition: { duration: 0.25 } },
		// You can do whatever you want here, if you just want it to stop completely use `rotate: 0`
		// stop: { y: [0, -10, 0], transition: { repeat: Infinity, repeatDelay: 3 } },
		easeIn: {
			x: -1000,
			transition: { delay: 0.05, duration: 0.2 },
			transitionEnd: {
				zIndex: 1,
				x: 0,
				opacity: 1,
			},
			name: 'easeIn',
		},
		center: { zIndex: 1, x: 0, opacity: 1, name: 'center' },
		easeOut: {
			zIndex: 0,
			x: 1000,
			opacity: 0,
			scale: 0,
			transition: { delay: 0, duration: 0.5 },
			transitionEnd: {
				x: -1000,
				opacity: 1,
				zIndex: 1,
			},
			name: 'easeOut',
		},
		none: {},
	};

	const endThisSession = () => {
		setLoadingFinalResults(true);
		setTimeout(() => {
			setLoadingFinalResults(false);
		}, 3000);
		setShowFinalResults(true);
	};

	const addOne = () => {
		let thisCard = theseFlashcards[currentCard].id;
		// console.info(thisCard);
		setCorrectas((correctas) => [...correctas, thisCard]);
		slideIntoNext();
	};

	const slideIntoNext = () => {
		setShowCard(false);
		setShowBack(false);

		if (currentCard + 1 !== state.length) {
			setCurrentCard(currentCard + 1);
		} else {
			storeFinalResults();
			return endThisSession();
		}
	};

	function leavingSoSoon() {
		let isConfirmed = window.confirm(
			'Si te sales de esta sesión perderás todo tu progreso, ¿estas segur@?'
		);
		if (isConfirmed) {
			return window.history.back();
		}
	}

	const storeFinalResults = async () => {
		let cards = [];
		await theseFlashcards.forEach((c) => {
			cards.push(c.id);
		});
		let thisSession = {
			banco: state.banco,
			length: state.length,
			mode: state.mode,
			topics: state.topics,
			correctas,
			selectedCards: cards,
		};
		console.info(thisSession);
		storeFlashcardsResults(thisSession);
	};

	if (loadingFinalResults) {
		return (
			<LoadingFull
				message={'Tus flashcards han terminado. Analizando resultados.'}
			/>
		);
	}

	if (showFinalResults) {
		return (
			<div className='full-bg' id='full-bg'>
				<img
					src={logo}
					alt='prepmed-logo'
					className='logo'
					style={{
						width: '12%',
						minWidth: '130px',
						position: 'absolute',
						left: '2%',
						top: '2%',
						zIndex: '9',
					}}
				/>
				<div className='test-mode bg-test-mode'>
					<div
						className='container d-flex-center'
						style={{ overflow: 'hidden' }}>
						<div className='center mt-4'>
							{/* PUT RESULTS HERE */}
							<div
								className='card'
								style={{
									minHeight: '250px',
									width: window.innerWidth < 767 ? '350px' : '400px',
								}}>
								<div className='card-content'>
									<h4>Resultados</h4>
									<p>
										Aquí está tu resultado final de está sesión de flashcards.
										¡Sigue haciéndolas! Las flashcards son una excelente
										herramienta de estudio que ayudan a internalizar conceptos
										más rápido.
									</p>
									<div className='d-flex-center'>
										<strong style={{ fontSize: 'xx-large' }}>
											{correctas.length}
										</strong>
										<span className='mx-1'>/</span>
										<span>{state.length}</span>
									</div>
									<p
										className='mt-1 mb-0'
										style={{ marginBottom: '0!important' }}>
										Tuviste {correctas.length} correctas de {state.length} en
										total.
									</p>
								</div>
								<div className='card-action center'>
									<Link to='/flashcards' className='btn btn-flat'>
										<span className='material-icons'>refresh</span>{' '}
										<span>Empieza De Nuevo</span>
									</Link>

									<button
										className='btn bg-secondary ml-0-m ml-2'
										onClick={() => {
											window.location.reload();
										}}>
										<span className='material-icons'>dashboard</span>{' '}
										<span>Panel Principal</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='full-bg' id='full-bg'>
			{/* LOGO */}
			<img
				src={logo}
				alt='prepmed-logo'
				className='logo'
				style={{
					width: '12%',
					minWidth: '130px',
					position: 'absolute',
					left: '2%',
					top: '2%',
					zIndex: '9',
					cursor: 'pointer',
				}}
				onClick={leavingSoSoon}
			/>

			<div className='test-mode bg-test-mode'>
				<div className='container d-flex-center' style={{ overflow: 'hidden' }}>
					<div className='center mt-4'>
						{/* PROGRESS BAR */}
						<div
							className='white-text d-flex'
							style={{
								justifyContent: 'end',
								height: '30px',
								width: window.innerWidth < 767 ? '350px' : '400px',
							}}>
							{showCard && showProgress && (
								<Progress
									total={state.length}
									currentQuestion={currentCard}
									maxQuestions={state.length}
								/>
							)}
						</div>

						{/* FRONT OF CARD */}
						<AnimatePresence exitBeforeEnter>
							{showCard && (
								<motion.div
									initial={variants.easeIn}
									animate={variants.center}
									exit={variants.easeOut}
									transition={{ duration: 0.85 }}
									onAnimationComplete={(a) => {
										// console.info('Completed animating:', a);
										if (a.name === 'easeOut') {
											setShowProgress(false);
											if (currentCard <= state.length) {
												return setShowCard(true);
											}
										}
										if (a.name === 'center') return setShowProgress(true);
									}}>
									<motion.div
										className='card d-flex-center mx-2'
										variants={variants}
										animate={animation}
										style={{
											boxShadow: '0px 0px 15px 10px rgba(0,0,0,0.25)',
											WebkitBoxShadow: '0px 0px 15px 10px rgba(0,0,0,0.25)',
											MozBoxShadow: '0px 0px 15px 10px rgba(0,0,0,0.25)',
											minHeight: '250px',
											width: window.innerWidth < 767 ? '350px' : '400px',
										}}
										onAnimationComplete={(animation) => {
											// console.info('Completed animating', animation);
											if (animation === 'rotate') {
												setShowBack(!showBack);
												setAnimation('rotateBack');
											}
										}}>
										<motion.button
											className='no-focus'
											style={{
												position: 'absolute',
												top: '1rem',
												right: '1rem',
											}}
											onClick={() => {
												setAnimation('rotate');
											}}
											whileHover={{ scale: 1.5 }}>
											<i className='material-icons right'>360</i>
										</motion.button>

										{/* CARD IMAGE */}
										{theseFlashcards[currentCard]?.hasOwnProperty('image') && (
											<div className='card-image'></div>
										)}

										{/*//~ CARD CONTENT */}
										<div
											className='card-content d-flex-center'
											style={{ width: '100%' }}>
											{!showBack ? (
												<span className='card-title'>
													{parseHTML(theseFlashcards[currentCard].front)}
												</span>
											) : (
												<span className='card-title'>
													{parseHTML(theseFlashcards[currentCard].back)}
												</span>
											)}
										</div>
									</motion.div>

									{/* //~BUTTONS */}
									<div className='mt-2'>
										{!showBack ? (
											<button
												className='btn bg-brand no-focus'
												onClick={() => {
													setAnimation('rotate');
												}}>
												<i className='material-icons left'>360</i>
												Ver Reverso
											</button>
										) : (
											<>
												<button className='btn bg-brand mr-1' onClick={addOne}>
													Correcta
												</button>
												<button
													className='btn bg-secondary'
													onClick={slideIntoNext}>
													Incorrecta
												</button>
											</>
										)}
									</div>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FlashcardsMode;
