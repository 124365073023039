import React from 'react';
import parse from 'html-react-parser';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';

const Explanation = ({ currentQuestion, imageURL }) => {
	const explanation = removeEmptyParagraphsbyQuill(currentQuestion.explanation);

	return (
		<React.Fragment>
			<div className='explanation'>{parse(explanation)}</div>
		</React.Fragment>
	);
};

export default Explanation;
