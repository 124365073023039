import React from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';

const EnurmBreakdown = () => {
	const { questions, getQuestions } = React.useContext(FirestoreContext);
	const [filteredQs, setFilteredQs] = React.useState([]);
	const [categories, setCategories] = React.useState([]);
	const [showList, setShowList] = React.useState(false);
	// const questions = JSON.parse(localStorage.getItem('prepmed-questions'));

	React.useEffect(() => {
		// console.info(questions.length);
		questions.length <= 0 && getQuestions();
		// eslint-disable-next-line
	}, []);

	const handleChange = (e) => {
		let fQs = questions.filter((q) => {
			return q.enurm === e.target.value;
		});
		setFilteredQs(fQs);
		getCategories(fQs);
	};

	const getCategories = (fQs) => {
		let thisTestsCategories = [];
		fQs.forEach((i) => {
			console.info(i.category);
			return thisTestsCategories.push(i.category);
		});
		thisTestsCategories = new Set(thisTestsCategories);
		setCategories([...thisTestsCategories]);
		setShowList(true);
		// return categories
	};

	return (
		<div>
			<div className='input-field'>
				<select
					// className='browser-default'
					id='enurm'
					name='enurm'
					className='browser-default'
					style={{ width: 'auto' }}
					defaultValue={'default'}
					onChange={handleChange}
					tabIndex='0'
					autoFocus>
					<option value='default' disabled>
						-- Elige un ENURM --
					</option>
					{/* <option value='prepmed'>PrepMep</option> */}
					<option value='enurm-2021-A'>ENURM 2021 A</option>
					<option value='enurm-2021-B'>ENURM 2021 B</option>
					<option value='enurm-2021-C'>ENURM 2021 C</option>
					<option value='enurm-2020'>ENURM 2020</option>
					<option value='enurm-2019'>ENURM 2019</option>
					<option value='enurm-2018'>ENURM 2018</option>
					<option value='enurm-2017'>ENURM 2017</option>
					<option value='enurm-2016'>ENURM 2016</option>
					<option value='enurm-2015'>ENURM 2015</option>
					<option value='enurm-2014'>ENURM 2014</option>
					<option value='enurm-2013'>ENURM 2013</option>
					<option value='enurm-2012'>ENURM 2012</option>
				</select>
				{/* <label htmlFor='enurm'>Lista de Enurms</label> */}
			</div>
			<div>
				{showList &&
					categories.length > 0 &&
					categories.map((category) => {
						return (
							<div key={category}>
								<p>
									{category}:{' '}
									{filteredQs.filter((q) => q.category === category).length}
								</p>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default EnurmBreakdown;
