import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo/LOGO_BLUE_FULL_COLOR.png';
import { SIGNUP_PAGE_NEW } from '../../utils/ROUTES';

const SocialLinks = () => {
	React.useEffect(() => {
		const nav = document.querySelector('nav');
		nav.classList.add('hide');

		const footer = document.querySelector('footer');
		footer.classList.add('hide');

		const copyright = document.querySelector('.copyright');
		copyright.classList.add('hide');
		// return () => {
		//     cleanup
		// }
	}, []);

	return (
		<div
			className='bg-main'
			style={{
				height: '100%',
				minHeight: '100vh',
			}}>
			<div
				className='bg-main'
				style={{
					height: '100%',
					minHeight: '90vh',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					padding: '5vh 0',
				}}>
				{/* LOGO */}
				<div className='center'>
					<img
						src={logo}
						alt='prepmed-logo'
						className='img-reponsive w-65 pt-5'
					/>
				</div>
				{/* LINK SHORTCUTS */}
				<div className='row flex-center' style={{ flexDirection: 'column' }}>
					{/* CREA TU CUENTA */}
					<Link
						to={SIGNUP_PAGE_NEW}
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						<strong>¡ CREA TU CUENTA !</strong>
					</Link>

					{/* // Entrevista Tavarez */}
					<a
						href='https://youtu.be/S_hB-eVCjgw'
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						Entrevista Dra. Tavarez - Anestesiología 2021
					</a>

					{/* // Entrevista Lockward */}
					<a
						href='https://youtu.be/t9Xy67lHIEA'
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						Entrevista Dra. Lockward - Pediatría 2021
					</a>

					{/* // Entrevista Espertin */}
					<a
						href='https://www.youtube.com/watch?v=wT7vay8ZyFc'
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						Entrevista Dra. Espertín - Otorrino 2021
					</a>

					{/* LINK LISTA DE ESPERA */}
					<Link
						to='/lista-de-espera-enurm-2023'
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						Lista de Espera ENURM 2023
					</Link>
					{/* LINK LISTA DE ESPERA */}
					{/* <Link
						to='/lista-de-espera-enurm-2022'
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						Lista de Espera ENURM 2022
					</Link> */}
					{/* ///LINK
				<Link
					to='/consulta-enurm21'
					className='social-links'
					target='_blank'
					rel='noopener noreferrer'>
					Consulta Concursantes ENURM 2021
				</Link> */}
					{/* ///COVID LINK */}
					{/* <Link
						to='/covid'
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						Reporte Vacunas COVID-19
					</Link> */}

					{/* ///PREPMED LINK */}
					<Link
						to='/'
						className='social-links'
						target='_blank'
						rel='noopener noreferrer'>
						Visita Nuestra Página
					</Link>
				</div>

				{/* REDES SOCIALES */}
				<div className='center'>
					<h6>Síguenos</h6>
					<ul className='flex-center mt-2 '>
						<li>
							<a href='https://www.instagram.com/prepmedrd/'>
								{/* Instagram */}
								<i
									className='mr-4 fab fa-instagram fa-3x txt-brand'
									style={{ opacity: '0.8' }}></i>
							</a>
						</li>
						<li>
							<a href='https://twitter.com/prepmedrd'>
								{/* Twitter */}
								<i
									className='mr-4 fab fa-twitter fa-3x txt-brand'
									style={{ opacity: '0.8' }}></i>
							</a>
						</li>
						<li>
							<a href='https://www.facebook.com/prepmedrd'>
								{/* Facebook */}
								<i
									className='fab fa-facebook fa-3x txt-brand'
									style={{ opacity: '0.8' }}></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SocialLinks;
