import React, {
	useReducer,
	useContext,
	useEffect,
	useState,
	useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import firebase from '../../../../utils/store/config/fbConfig';
import logo from '../../../../assets/logo/maxilofacial.png';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';
import { AuthContext } from '../../../../utils/store/context/authContext';
import quizReducer from '../../../../utils/store/context/reducers/quizReducer';

import Timer from './Timer';
import Progress from './Progress';
import Question from './Question';
import Answers from './Answers';
import FinalResults from './FinalResults';
import {
	NEXT_QUESTION,
	UPDATE_SCORE,
	UPDATE_TIMER,
	UPDATE_TIMER_START,
	SELECTED_ANSWER,
	SET_TEST_QUESTIONS,
} from '../../../../utils/store/context/types';
import Explanation from './Explanation';
import { scrollToTop } from '../../../../utils/helpers';
import LoadingFull from '../../../Helpers/LoadingFull';
import DisplayImage from './DisplayImage';

const TestMode = (props) => {
	let history = useHistory();
	const submitButton = useRef();
	const { updateQuestionbyKey, getImageURL, imageURL } =
		useContext(FirestoreContext);
	const { user, storeUserTestResults, testSelections } =
		useContext(AuthContext);

	const initialState = {
		// userTestSelections: { ...props.location.state },
		userTestSelections: { ...testSelections },
		score: 0,
		currentQuestion: 0,
		selectedAnswer: '',
		selectedQuestionsAndAnswers: [],
		get testLength() {
			// return props.location.state.testLength;
			return testSelections.testLength;
		},
		get testQuestionsSelected() {
			// return this.userTestSelections.questions.slice(0, this.testLength);
			return this.userTestSelections.questions.slice(0, this.testLength);
		},
		timerStartTime: null,
		timeElapsedInSeconds: 0,
	};
	const [state, dispatch] = useReducer(quizReducer, initialState);
	const [showFinalResults, setShowFinalResults] = useState(false);
	const [showExplanation, setShowExplanation] = useState(false);
	const [questionCount, setQuestionCount] = useState(1);
	const [isAnswerSelected, setIsAnswerSelected] = useState(false);
	const [testFinished, setTestFinished] = useState(false);
	const [userSelectedAnswer, setUserSelectedAnswer] = useState();
	const [currentQImgURL, setCurrentQImgURL] = useState('');

	useEffect(() => {
		const nav = document.querySelector('nav');
		nav.classList.add('hide');

		const footer = document.querySelector('footer');
		footer.classList.add('hide');

		const copyright = document.querySelector('.copyright');
		copyright.classList.add('hide');

		scrollToTop();

		// newTest();

		return () => {
			nav.classList.remove('hide');
			footer.classList.remove('hide');
			copyright.classList.remove('hide');
		};
	}, []);

	useEffect(() => {
		window.addEventListener('keyup', onEnterNextQuestion, false);

		return () => {
			window.removeEventListener('keyup', onEnterNextQuestion, false);
		};
		/*eslint-disable-next-line */
	}, [isAnswerSelected]);

	useEffect(() => {
		let thisQuestion =
			state.userTestSelections.questions[state.currentQuestion];
		console.info(thisQuestion, thisQuestion?.hasOwnProperty('imagePath'));

		//!HAS IMAGE
		if (thisQuestion?.hasOwnProperty('imagePath')) {
			console.info(thisQuestion);
			console.info('GETTING IMAGE URL OF:', thisQuestion.imagePath);
			// getImageURL(thisQuestion.imagePath);
			getQuestionImageURL(thisQuestion.imagePath);
		}

		// !IF TEST IS FINISHED
		if (questionCount > state.testLength) {
			storeTestData();
			setTestFinished(true);

			setTimeout(() => {
				setTestFinished(false);
			}, 3000);

			setShowFinalResults(true);
		}
		// return () => {
		// 	cleanup
		// };

		/*eslint-disable-next-line */
	}, [questionCount]);

	const getQuestionImageURL = async (path) => {
		const storage = firebase.storage();
		const pathReference = storage.ref(`question_images/${path}`);
		let thisPath = await pathReference.getDownloadURL();
		setCurrentQImgURL(thisPath);
	};

	/**
	 * THis happens 1st, right as a user selects their answer
	 */
	const evalAnswer = (userSelection) => {
		setIsAnswerSelected(true);
		setUserSelectedAnswer(userSelection);

		dispatch({ type: SELECTED_ANSWER, payload: userSelection });

		// If in Tutor Mode, show answer
		if (state.userTestSelections.isTutorMode) {
			submitUserSelection(userSelection);
		}

		submitButton.current.focus();
	};

	/**
	 * Show the next question
	 */
	const nextQuestion = () => {
		if (state.userTestSelections.useTimer) {
			updateTimeElapsed(Date.now());
		}

		if (!state.userTestSelections.isTutorMode) {
			submitUserSelection(userSelectedAnswer);
		}

		if (state.userTestSelections.isTutorMode) {
			setShowExplanation(false);
		}
		setShowExplanation(false);

		setQuestionCount((questionCount) => questionCount + 1);

		setIsAnswerSelected(false);

		dispatch({
			type: NEXT_QUESTION,
			payload: state.currentQuestion,
		});
		window.removeEventListener('keyup', onEnterNextQuestion, false);
		scrollToTop();
	};

	const submitUserSelection = (userSelection) => {
		dispatch({
			type: SET_TEST_QUESTIONS,
			payload: state.currentQuestion,
		});

		if (
			userSelection ===
			state.userTestSelections.questions[state.currentQuestion].answer
		) {
			dispatch({
				type: UPDATE_SCORE,
				payload: state.score,
			});
		}
	};

	const updateTimerStartTime = (passed) => {
		dispatch({ type: UPDATE_TIMER_START, payload: passed });
	};

	//~---------------
	const onEnterNextQuestion = (e) => {
		// console.info(isAnswerSelected, e);
		if (e.keyCode === 13 || e.charCode === 13) {
			isAnswerSelected && nextQuestion();
		}
	};

	function leavingSoSoon() {
		let isConfirmed = window.confirm(
			'Si te sales del examen perderás todo tu progreso, ¿estas segur@?'
		);
		if (isConfirmed) {
			return history.push('/elige');
		}
	}

	const updateTimeElapsed = (passed) => {
		dispatch({ type: UPDATE_TIMER, payload: passed });
	};

	const stopTest = () => {
		updateTimeElapsed(Date.now());
		alert('El tiempo límite para completar el examen ha terminado.');
		setIsAnswerSelected(false);
		setQuestionCount(state.testLength);
	};

	if (testFinished) {
		return (
			<LoadingFull message={'Tu examen ha terminado. Analizando resultados.'} />
		);
	}

	const storeTestData = async () => {
		const thisTestData = {
			questions: [...state.selectedQuestionsAndAnswers],
			score: state.score,
			testDuration: state.timeElapsedInSeconds,
			testLabel: state.userTestSelections.testLabel,
			testLength: state.testLength,
			testMode: state.userTestSelections.testMode,
			testTopics: [...state.userTestSelections.topics],
			testType: state.userTestSelections.testType,
			useTimer: state.userTestSelections.useTimer,
			useTutorMode: state.userTestSelections.useTutorMode,
			timeElapsedInSeconds: state.userTestSelections.useTimer
				? state.timeElapsedInSeconds
				: 0,
		};

		storeUserTestResults(user.uid, thisTestData);
	};

	return (
		<div className='full-bg'>
			{showFinalResults ? (
				<img
					src={logo}
					alt='prepmed-logo'
					className='logo'
					style={{
						width: '12%',
						minWidth: '130px',
						position: 'absolute',
						left: '2%',
						top: '2%',
						zIndex: '9',
					}}
				/>
			) : (
				<img
					src={logo}
					alt='prepmed-logo'
					className='logo'
					style={{
						width: '12%',
						minWidth: '130px',
						position: 'absolute',
						left: '2%',
						top: '2%',
						zIndex: '9',
						cursor: 'pointer',
					}}
					onClick={leavingSoSoon}
				/>
			)}

			<div className='test-mode bg-test-mode'>
				<div className='container'>
					{showFinalResults ? (
						<FinalResults state={{ ...state }} />
					) : (
						<React.Fragment>
							<div className='row'>
								<div className='col offset-s6 s6 right-align white-text'>
									<Progress
										total={state.testLength}
										currentQuestion={state.currentQuestion}
										maxQuestions={state.testLength}
									/>

									{state.userTestSelections.useTimer && (
										<Timer
											testDuration={state.userTestSelections.testDuration}
											startTimer={state.userTestSelections.useTimer}
											updateTimeElapsed={updateTimeElapsed}
											updateTimerStartTime={updateTimerStartTime}
											stopTest={stopTest}
											timeElapsed={state.timeElapsedInSeconds}
										/>
									)}
								</div>
							</div>

							{questionCount < state.testLength + 1 && (
								// questionCount <= state.testLength + 1 && (
								<div>
									{/* Question */}
									<div className='row'>
										<div className='col s12'>
											<Question
												currentQuestion={
													state.userTestSelections.questions[
														state.currentQuestion
													]
												}
												update={updateQuestionbyKey}
											/>
											{state.userTestSelections.questions[
												state.currentQuestion
											].hasOwnProperty('imagePath') &&
												state.userTestSelections.questions[
													state.currentQuestion
												].showImageInQuestion && (
													<DisplayImage imageURL={currentQImgURL} />
												)}
										</div>
									</div>

									{/* Answers */}
									<div className='row'>
										<div className='col s12'>
											<Answers
												currentQuestion={
													state.userTestSelections.questions[
														state.currentQuestion
													]
												}
												usersChoice={evalAnswer}
												selected={isAnswerSelected}
												userSelection={state.selectedAnswer}
												isTutorMode={state.userTestSelections.useTutorMode}
											/>
										</div>
									</div>

									{showExplanation && (
										<div className='row'>
											<div className='col s12'>
												<div className='card-panel bg-test-mode-explanation'>
													<h5>Explicación:</h5>
													<Explanation
														currentQuestion={
															state.userTestSelections.questions[
																state.currentQuestion
															]
														}
													/>
													{state.userTestSelections.questions[
														state.currentQuestion
													].hasOwnProperty('imagePath') &&
														!state.userTestSelections.questions[
															state.currentQuestion
														].showImageInQuestion && (
															<DisplayImage
																imageURL={currentQImgURL}></DisplayImage>
														)}
												</div>
											</div>
										</div>
									)}

									<div
										className={
											isAnswerSelected ? 'next-question-button' : 'd-none'
										}
										ref={submitButton}
										onKeyUp={onEnterNextQuestion}>
										{state.userTestSelections.useTutorMode && (
											<button
												className='btn btn-large bg-secondary'
												onClick={() => setShowExplanation(!showExplanation)}
												style={{ marginRight: '1vmax' }}>
												{!showExplanation ? 'Ver' : 'Esconder'} Explicación
											</button>
										)}
										<button
											className='btn btn-large bg-next-question z-depth-3'
											onClick={nextQuestion}>
											Siguiente Pregunta
										</button>
									</div>
								</div>
							)}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default TestMode;
