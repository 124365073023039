import React from 'react';
import logo from '../../../assets/logo/maxilofacial.png';
import { FirestoreContext } from '../../../utils/store/context/firestoreContext';
import Answers from '../quiz/questionaire/Answers';
import Question from '../quiz/questionaire/Question';

const QuestionDetailsDark = (props) => {
	const { questions } = React.useContext(FirestoreContext);
	// const [question, setQuestion] = React.useState();

	// React.useEffect(() => {
	// 	setQuestion(
	// 		questions.filter((q) => {
	// 			return q.id === props.location.state.id;
	// 		})
	// 	);
	// }, []);

	const question = questions.filter((q) => {
		return q.id === props.location.state.id;
	})[0];

	const doNothing = () => {};

	return (
		<div className='full-bg'>
			<img
				src={logo}
				alt='prepmed-logo'
				className='logo'
				style={{
					width: '12%',
					minWidth: '130px',
					position: 'absolute',
					left: '2%',
					top: '2%',
					zIndex: '9',
					cursor: 'pointer',
				}}
			/>

			<div className='test-mode bg-test-mode'>
				<div className='container mt-4'>
					<React.Fragment>
						{/* <div className='row'>
									<div className='col offset-s6 s6 right-align white-text'>
										<Progress
											total={state.testLength}
											currentQuestion={state.currentQuestion}
											maxQuestions={state.testLength}
										/>

										{state.userTestSelections.useTimer && (
											<Timer
												testDuration={state.userTestSelections.testDuration}
												startTimer={state.userTestSelections.useTimer}
												updateTimeElapsed={updateTimeElapsed}
												updateTimerStartTime={updateTimerStartTime}
											/>
										)}
									</div>
								</div> */}

						{/* Question */}
						<div className='row'>
							<div className='col s12'>
								<Question update={doNothing} currentQuestion={question} />
							</div>
						</div>

						{/* Answers */}
						<div className='row'>
							<div className='col s12'>
								<Answers currentQuestion={question} />
							</div>
						</div>

						{/* {showExplanation && (
									<div className='row'>
										<div className='col s12'>
											<div className='card-panel bg-test-mode-explanation'>
												<h5>Explicación:</h5>
												<Explanation
													currentQuestion={
														state.userTestSelections.questions[
															state.currentQuestion
														]
													}
												/>
											</div>
										</div>
									</div>
								)} */}

						{/* <div className='container right-align'>
							<button className='btn btn-large bg-next-question z-depth-3'>
								Siguiente Pregunta
							</button>
						</div> */}
					</React.Fragment>
				</div>
			</div>
		</div>
	);
};

export default QuestionDetailsDark;
