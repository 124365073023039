import React from 'react';

const ListaDeEspera = () => {
	React.useEffect(() => {
		window.location.replace(
			'https://mailchi.mp/afc7df538713/lista-de-espera-2023'
		);
		// window.location.replace(
		// 	'https://mailchi.mp/cc9607ed4529/lista-de-espera-enurm-2022'
		// );
		// return () => {
		//     cleanup
		// }
	}, []);
	return <div>Espera un momento por favor...</div>;
};

export default ListaDeEspera;
