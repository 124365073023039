import React from 'react';

/**
 * Takes in 'habdleChange
 */
const InputCedula = ({ handleChange }) => {
	const cedulaRef = React.useRef('');
	const [showFormatoValidoCedula, setShowFormatoValidoCedula] = React.useState(
		false
	);

	return (
		<>
			<div className='input-field col s12 m6'>
				<i className='material-icons prefix txt-brand2'>portrait</i>
				<label htmlFor='cedula'>Número de Cédula</label>
				<input
					type='text'
					id='cedula'
					ref={cedulaRef}
					onFocus={(e) => setShowFormatoValidoCedula(true)}
					onBlur={(e) => setShowFormatoValidoCedula(false)}
					onChange={(e) => handleChange(e.target.value)}
					minLength={11}
					maxLength={13}
					pattern='[0-9]{3}[-][0-9]{7}[-][0-9]|[0-9]{11}'
					autoComplete='off'
					required
				/>
				{cedulaRef.current.value !== '' && (
					<span className={cedulaRef.current?.validity && 'invalid'}></span>
				)}
				<sup className='ml-4'>(required)</sup>
			</div>
			{showFormatoValidoCedula && (
				<div className='col s12'>
					<div className='col offset-m6 m6 s12'>
						<p className='center fsize-1 txt-danger m-0'>
							Formatos Válidos: *** - ******* - * | ***********
						</p>
					</div>
				</div>
			)}
		</>
	);
};

export default InputCedula;
