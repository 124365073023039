// HOW IT WORKS: https://youtu.be/Ix_xeCuS4XA?list=PLZlA0Gpn_vH-aEDXnaFNLsqiJWFpIWV03&t=286
// SOURCE CODE: https://github.com/WebDevSimplified/useful-custom-react-hooks/blob/main/src/13-useEventListener/useEventListener.js
// TODO: Define right types

import { useEffect, useRef } from 'react';

export default function useEventListener(
	eventType: string,
	callback: (v: Event) => void,
	element: any = window
	// element: Document | Window | HTMLElement = window
) {
	// TODO: Define right types
	const callbackRef = useRef(callback);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		if (element == null) return;
		// TODO: Define right type
		const handler = (e: any) => callbackRef.current(e);
		element.addEventListener(eventType, handler);

		return () => element.removeEventListener(eventType, handler);
	}, [eventType, element]);
}

// IMPLEMENTATION EXAMPLE
// export default function EventListenerComponent() {
// 	const [key, setKey] = useState('');
// 	useEventListener('keydown', (e) => {
// 		setKey(e.key);
// 	});

// 	return <div>Last Key: {key}</div>;
// }
