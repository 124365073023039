import React, { useState, useRef } from 'react';
import { showInvalidMessage } from '../../../../utils/validation_helpers';

const Step2 = ({
	nextStep,
	thisUser,
	updateThisUser,
	updateNewUserInformationInFirestore,
}) => {
	//~ REFS
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const dobRef = useRef('');
	// const cedulaRef = useRef();

	//~ INPUT FIELDS
	const [firstName, setFirstName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');
	const [secondLastName, setSecondLastName] = useState('');
	const [dob, setDoB] = useState('');
	const [dobFocus, setDoBFocus] = useState(false);
	// const [cedula, setCedula] = useState('');

	//~ VALIDATIONS
	// const [showFormatoValidoCedula, setShowFormatoValidoCedula] = useState(false);
	const [allFieldsValid, setAllFieldsValid] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState('');

	const checkIfAllFieldsValid = () => {
		setShowErrorMessage('');

		if (
			firstNameRef.current.validity.valid &&
			lastNameRef.current.validity.valid &&
			dobRef.current.validity.valid
			// 		// cedulaRef.current.validity.valid &&
		) {
			setAllFieldsValid(true);
			setShowErrorMessage('');
			// console.info('All fields valid.');
			return;
		} else {
			setAllFieldsValid(false);
			if (!firstNameRef.current.validity.valid) {
				setShowErrorMessage(
					'Por favor verifíca el campo "Primer Nombre". Nota: No se permiten espacios.'
				);
				return;
			}
			if (!lastNameRef.current.validity.valid) {
				setShowErrorMessage(
					'Por favor verifíca el campo "Primer Apellido". Nota: No se permiten espacios.'
				);
				return;
			}
			if (!dobRef.current?.validity.valid) {
				setShowErrorMessage(
					'Por favor verifíca el campo "Fecha de Nacimiento". El único formato aceptado es: DD/MM/AAAA (día/més/año) y el año debe empezar con 1 o 2 (ej. 1990 o 2000).'
				);
				return;
			}
			// 		// if (!cedulaRef.current.validity.valid) {
			// 		// 	setShowErrorMessage('Por favor verifica el campo "Cedula".');
			// 		// 	return;
			// 		// }
		}
	};

	const handleChange = (e) => {
		// console.log('HANDLE CHANGE', e.target.value);
		e.target.setCustomValidity('');
		switch (e.target.id) {
			case 'firstName':
				setFirstName(e.target.value);
				break;
			case 'middleName':
				setMiddleName(e.target.value);
				break;
			case 'lastName':
				// checkValidation(e;
				setLastName(e.target.value);
				break;
			case 'secondLastName':
				setSecondLastName(e.target.value);
				break;
			case 'date-of-birth':
				// console.info(dobRef.current?.validity);
				setDoB(e.target.value);
				break;
			default:
				break;
		}
	};

	const handleDateChange = () => {
		if (dob !== '' || dob !== dobRef.current.value) {
			setDoB(dobRef.current.value);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Próximo Paso
		updateThisUser({
			firstName,
			middleName,
			lastName,
			secondLastName,
			initials: firstName[0] + lastName[0],
			...thisUser,
		});
		updateNewUserInformationInFirestore(thisUser.uid, {
			firstName,
			middleName,
			lastName,
			secondLastName,
			initials: firstName[0] + lastName[0],
			...thisUser,
		});
		nextStep();
	};

	return (
		<form
			onSubmit={handleSubmit}
			onChange={() => {
				checkIfAllFieldsValid();
				handleDateChange();
			}}
			className='white row'
			autoComplete='off'>
			{/*//~ PRIMER NOMBRE */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>account_box</i>
				<label htmlFor='firstName'>Primer Nombre</label>
				<input
					type='text'
					id='firstName'
					onChange={handleChange}
					ref={firstNameRef}
					onInvalid={(event) =>
						showInvalidMessage(
							event,
							'Este campo no permite espacios en blanco.'
						)
					}
					pattern='\S+'
					autoComplete='off'
					required
					autoFocus
				/>
				<span className={firstNameRef.current?.validity && 'invalid'}></span>

				<sup className='ml-4'>(requerido)</sup>
			</div>
			{/*//~ SEGUNDO NOMBRE */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>account_box</i>
				<label htmlFor='middleName'>Segundo Nombre</label>
				<input type='text' id='middleName' onChange={handleChange} />
				<sup className='ml-4'>(opcional)</sup>
			</div>
			{/*//~ PRIMER APELLIDO */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>account_box</i>
				<label htmlFor='lastName'>Primer Apellido</label>
				<input
					type='text'
					id='lastName'
					ref={lastNameRef}
					onChange={handleChange}
					onInvalid={(event) =>
						showInvalidMessage(
							event,
							'Este campo no permite espacios en blanco.'
						)
					}
					pattern='\S+'
					autoComplete='off'
					required
				/>
				<span className={lastNameRef.current?.validity && 'invalid'}></span>
				<sup className='ml-4'>(requerido)</sup>
			</div>
			{/*//~ SEGUNDO APELLIDO */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>account_box</i>
				<label htmlFor='secondLastName'>Segundo Apellido</label>
				<input type='text' id='secondLastName' onChange={handleChange} />
				<sup className='ml-4'>(opcional)</sup>
			</div>
			{/*//~ DATE OF BIRTH */}
			<div className='input-field col s12'>
				<i className='material-icons prefix txt-brand'>event</i>
				<label htmlFor='date-of-birth'>Fecha de Nacimiento</label>
				<input
					type='text'
					id='date-of-birth'
					ref={dobRef}
					onFocus={() => setDoBFocus(true)}
					onBlur={() => setDoBFocus(false)}
					onChange={handleChange}
					pattern='(\d){2}/(\d){2}/(1|2)(\d){3}'
					maxLength='10'
					required
				/>
				<span className={dobRef.current?.validity && 'invalid'}></span>
				<sup className='ml-4' hidden={dobFocus}>
					(requerido)
				</sup>
				{dobFocus && (
					<p
						className={`invalid-text ${
							dobRef.current?.validity?.valid && 'txt-success'
						} d-inline`}>
						{dobRef.current?.validity?.valid
							? 'Válida'
							: 'El formato debe ser: MM/DD/AAAA'}
					</p>
				)}

				{/* <span hidden={!dobFocus} className='align-right'>
							Formato Válido: **-**-****
						</span> */}
			</div>
			{/*//~ CEDULA	 */}
			{/* <div className='input-field col s12 m6'>
						<i className='material-icons prefix txt-brand2'>portrait</i>
						<label htmlFor='cedula'>Número de Cédula</label>
						<input
							type='text'
							id='cedula'
							ref={cedulaRef}
							onFocus={(e) => setShowFormatoValidoCedula(true)}
							onBlur={(e) => setShowFormatoValidoCedula(false)}
							onChange={handleChange}
							minLength={11}
							maxLength={13}
							pattern='[0-9]{3}[-][0-9]{7}[-][0-9]|[0-9]{11}'
							autoComplete='off'
						/>
						<span className={cedulaRef.current?.validity && 'invalid'}></span>
						<sup className='ml-4'>(opcional)</sup>
					</div>
					{showFormatoValidoCedula && (
						<div className='col s12'>
							<div className='col offset-m6 m6 s12'>
								<p className='center fsize-1 txt-danger m-0'>
									Formatos Válidos: *** - ******* - * | ***********
								</p>
							</div>
						</div>
					)} */}

			<div className='col s12'>
				{/*//~ VALIDATION ERRORS */}
				<div
					className='txt-danger center-align col s12'
					style={{ display: 'block' }}>
					{!allFieldsValid && showErrorMessage && (
						<p className='bg-danger p-1 white-text center'>
							{showErrorMessage}
						</p>
					)}
				</div>

				{/* //~NEXT STEP MESSAGE */}
				{allFieldsValid && (
					<div className='center'>
						<p className='txt-brand2'>
							Presiona el botón de 'Continuar' para llenar los datos de tu
							universidad, año de graduación y especialidades preferidas.
						</p>
					</div>
				)}

				{/* //~ BUTTONS */}
				<div className='right-align mb-4'>
					<button
						disabled={!allFieldsValid}
						className='btn btn-large bg-brand'
						// onClick={(e) => setDoB(dobRef.current.value)}
						type='submit'>
						Continuar
						<i className='material-icons right'>send</i>
					</button>
				</div>
			</div>
		</form>
	);
};

export default Step2;
