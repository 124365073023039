import React from 'react';
import Counter from './counters/Counter';
const Stats = ({ questions }) => {
	return (
		<div>
			<Counter questions={questions} />
		</div>
	);
};

export default Stats;
